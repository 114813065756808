<template>
	<div class="clienteCell">
		<div class="clienteData">
			<span class="nome">{{ nome }}</span>
			<span class="cpf">{{ cpf }}</span> <!-- CPF com fonte menor e cor mais escura -->
		</div>
	</div>
</template>

<script>
export default {
	props: ["params"],
	computed: {
		nome() {
			const partes = this.params.data.displayCliente.split(',');
			return partes.length > 1 ? partes[1].trim() : ''; 
		},
		cpf() {
			const partes = this.params.data.displayCliente.split(',');
			return partes[0].trim();
		},
	},
};
</script>

<style scoped>
.nome {
	margin: 0;
	font-size: 13px;
	font-weight: bold;
	line-height: 2;
}

.cpf {
	margin: 0;
	font-size: 11px;
	line-height: 1;
	font-weight: bold;
}

.clienteCell {
	margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.clienteData {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.clienteData > span {
  margin-bottom: 0px;
}

</style>
