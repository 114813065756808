import { render, staticRenderFns } from "./NotaGridDemonstracao.vue?vue&type=template&id=65f43aad&scoped=true&"
import script from "./NotaGridDemonstracao.vue?vue&type=script&lang=js&"
export * from "./NotaGridDemonstracao.vue?vue&type=script&lang=js&"
import style0 from "./NotaGridDemonstracao.vue?vue&type=style&index=0&id=65f43aad&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65f43aad",
  null
  
)

export default component.exports