






























import Vue from "vue";
import { VendaDemonstracaoResumida } from "@/models";
import UserLoginStore from "@/store/vuex/authentication/UserLoginStore";

export default Vue.extend({
	props: {
		params: {
			type: Object,
			required: true,
		},
	},
	methods: {
		emitAction(acao: string) {
			this.$emit("acao", { acao, dados: this.params.data });
		},
		validarSePodeCancelarVenda(venda: VendaDemonstracaoResumida): boolean {
			if (venda.status === "0") return false;
			
			if (
				!venda.temDevolucao &&
				venda.situacao !== "Cancelada" &&
				venda.notaFiscal &&
				(!venda.possuiPagamentoTefCreditado || venda.possuiPagamentoTefCancelado)
			) {
				return true;
			}

			return false;
		},
		podeCancelarPagamento(venda: VendaDemonstracaoResumida) {
			if (!venda.pdv) return true
			const limiteDeDias = venda.limiteDeDiasParaCancelarNota || 0

			const temNotasValidas = venda.status !== '101' && venda.status !== '102'

			if (temNotasValidas && limiteDeDias > 0) {
				const dhEmiMaisLimite = new Date(venda.dataEmissaoDaNota)
				dhEmiMaisLimite.setDate(dhEmiMaisLimite.getDate() + limiteDeDias)

				if (limiteDeDias > 0 && new Date() <= dhEmiMaisLimite) {
					return true
				}
			} else if (venda.notaFiscal.length == 0) {
				return true
			}

			return false
		},	
		temNotaParaEnviarEmail(venda: VendaDemonstracaoResumida) {
			return (
				venda.status === '100'
			)
		},
		podeEnviarPorEmail() {
			return UserLoginStore.permiteRegraDeNegocio('pode-enviar-notas-por-email')
		},
	},
});
