<template>
	<v-chip
		class="custom-chip"
		:style="{ 
			color: getTextColor(params.data.modalidade),
			backgroundColor: '#757575'
		}"
		small
	>
		{{ params.data.modalidade ? params.data.modalidade : 'Sem Modalidade' }}
	</v-chip>
</template>
  
<script>
export default {
	props: ["params"],
	methods: {
		getTextColor(modalidade) {
			switch (modalidade) {
				case 'Presencial':
					return this.$vuetify.theme.dark ? '#7BEB76' : '#76FF03';
				case 'Devolução':
					return this.$vuetify.theme.dark ? '#FF991F' : '#FF8A65';
				case 'Demonstração':
					return '#FFFFFF';
				case 'Ecommerce':
					return '#71a9fc';
				case 'Link':
					return '#E3C656';
				default:
					return '#E35FF5';
			}
		},
	},
};
</script>

<style>
</style>
