











































































































































































































































































































































































































































































































































































































































import { Vue, Component, Watch, Ref, Prop } from 'vue-property-decorator'
import { CancelarVendaUseCase, EditarUmaVendaUseCase, EmailUseCase, FindLojaUseCase } from '@/usecases'
import { FiltroDeVenda, NotaDaVenda, PontoDeVenda, ResumoDaVenda, MotivosCancelamentoFiscal, VendaDemonstracaoResumida } from '@/models'
import { VendaModule } from '@/store/vuex/venda/VendaStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import {
	formatarMoeda,
	removerFormatacaoDeCnpjOuCpf,
} from '@/shareds/formatadores'
import GridDeVenda from '@/views/application/venda/GridDeVenda.vue'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import DialogoDeDetalhesDaVenda from '@/components/venda/DialogoDeDetalhesDaVenda.vue'
import {
	gerarNotaNaoEletronica,
	obterDisplayClienteDaVenda,
	obterTotalDaVenda,
} from '@/shareds/venda-shareds'
import mapErrosSefaz from '@/shareds/fiscal/tabelas/mapErrosSefaz'
import moment from 'moment'
import { FindVendaUseCase } from '@/usecases'
import SelecaoDePdv from '@/components/venda/SelecaoDePdv.vue'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import CamposDeFiltrosDeVendas from '@/views/application/venda/CamposDeFiltrosDeVendas.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { nextTick } from '@/shareds/utils'
import { CancelarNotaFiscalUseCase } from '@/usecases/fiscal/CancelarNotaFiscalUseCase'
import RodapePersonalizado from '@/components/ui/data-tables/RodapePersonalizado.vue'
import DialogoDeInformarEmail from './DialogoDeInformarEmail.vue'
import DialogoDeCancelarPagamentos from '@/components/venda/DialogoDeCancelarPagamentos.vue'
import RestPdvService from '@/services/pdv/RestPdvService'
import DialogoDeDetalhesDoTicket from '@/components/venda/DialogoDeDetalhesDoTicket.vue'
import ConfirmacaoComMotivoFiscal from '@/components/ui/ConfirmacaoComMotivoFiscal.vue'
import GridDeDemonstracoes from '@/components/ui/GridDeDemonstracoes.vue'

const FILTRO_DE_VENDAS = 'FILTRO_DE_VENDAS'

@Component({
	components: {
		Confirmacao,
		GridDeVenda,
		DialogoDeDetalhesDaVenda,
		CamposDeFiltrosDeVendas,
		SeletorDeLojasDoUsuario,
		SelecaoDePdv,
		RodapePersonalizado,
		DialogoDeInformarEmail,
		DialogoDeCancelarPagamentos,
		DialogoDeDetalhesDoTicket,
		ConfirmacaoComMotivoFiscal,
		GridDeDemonstracoes,
	},
})
export default class TelaDeVendas extends Vue {
	@Ref() dialogoDeFiltros!: CamposDeFiltrosDeVendas
	@Ref() dialogoDeDetalhesDoTicket!: DialogoDeDetalhesDoTicket
	@Ref() form!: HTMLFormElement
	@Ref() dialogoDeInformarEmail!: DialogoDeInformarEmail
	@Ref() gridDeDemonstracoes!: GridDeDemonstracoes
	@Prop({ type: Array, default: undefined }) lojas?: string[]
	buscando = false
	buscandoGridDemonstracao = false

	vendas: ResumoDaVenda[] = []
	findUseCase = new FindVendaUseCase()
	editarUmaVendaUseCase = new EditarUmaVendaUseCase()

	findLojaUseCase = new FindLojaUseCase()
	cancelarVendaUseCase = new CancelarVendaUseCase()
	cancelarNotaFiscalUseCase = new CancelarNotaFiscalUseCase()
	pdvs: PontoDeVenda[] = []
	mostra = false
	buscandoPdvs = false
	motivoDoCancelamentoFiscal: MotivosCancelamentoFiscal | null = null
	numeroTicket: boolean | null = null

	pdvService = new RestPdvService()
	filtro: FiltroDeVenda = localStorage[FILTRO_DE_VENDAS]
		? JSON.parse(localStorage[FILTRO_DE_VENDAS])
		: {
			busca: '',
			loja: '',
			datas: [],
			horas: [null, null],
			cliente: '',
			exibeVendasComErros: false,
			ambientes: ['Produção', 'Homologação'],
			vendasComOrcamento: false,
			origem: '',
		}
	totalRegistros = -1
	dateTimeToPtBrFormat = dateTimeToPtBrFormat
	podeVisualizar: boolean | null = null

	cancelando: string[] = []
	cancelandoNotasFiscais: string[] = []
	enviandoEmail: string[] = []
	verificandoVenda: string[] = []
	demonstracaoHierarquia: VendaDemonstracaoResumida[] = []
	totalRegistrosDeDemonstracoes = -1
	
	tabs = ['Vendas', 'Demonstrações/Malinhas'] 

	paginacao = {
		page: 0,
		itemsPerPage: 10,
		itemsLength: 10,
	}

	paginacaoDeDemonstracoes = {
		page: 0,
		itemsPerPage: 10,
		itemsLength: 10,
	}

	get podeCancelarVenda() {
		return UserLoginStore.permiteRegraDeNegocio('pode-cancelar-venda')
	}

	get podeCancelarNota() {
		return UserLoginStore.permiteRegraDeNegocio('pode-cancelar-nota-fiscal')
	}

	get podeEnviarPorEmail() {
		return UserLoginStore.permiteRegraDeNegocio('pode-enviar-notas-por-email')
	}

	get temAcessoIrrestrito() {
		return UserLoginStore.permiteRegraDeNegocio('acesso-irrestrito')
	}

	get tab() {
		if (!this.$route.query.t) return 0
		const index = this.tabs.findIndex(tab => tab === this.$route.query.t)
		return index !== -1 ? index : null
	}

	set tab(value: number | null) {
		const tab = typeof value === 'number' ? this.tabs[value] : undefined
		this.$router
			.push({
				name: this.$route.name as string,
				query: { t: !value ? undefined : tab },
			})
			.catch()
		this.paginacaoDeDemonstracoes = {
			page: 0,
			itemsPerPage: 10,
			itemsLength: 10,
		}

		this.paginacao = {
			page: 0,
			itemsPerPage: 10,
			itemsLength: 10,
		}

		this.buscar()
	}

	validarSeTemTicket(venda: ResumoDaVenda) {
		if (venda.numeroTicket) {			
			return this.numeroTicket = true
		} else {			
			return this.numeroTicket = false
		}
	}

	validarSePodeCancelarVenda(venda: ResumoDaVenda) {
		if (venda.notas.filter(nota => nota.cstat === '0').length > 0) return false
		if (
			!venda.temDevolucao &&
			venda.situacao !== 'Cancelada' &&
			venda.notas &&
			(!venda.possuiPagamentoTefCreditado || venda.possuiPagamentoTefCancelado)
		)
			return true

		return false
	}

	get exibirSomenteVendasDoPdvAtivo() {
		return UserLoginStore.permiteRegraDeNegocio('exibir-somente-vendas-do-pdv-ativo')
	}

	get pdvLogado() {
		return this.turnoDeVenda?.pontoDeVenda.ativo
	}

	displayNfSerie(venda: ResumoDaVenda) {
		const nota = venda.notas[0]
		return nota ? nota.nnf + ' / ' + nota.serie : ''
	}

	displayTotalDaVenda(venda: ResumoDaVenda) {
		return formatarMoeda(venda.total)
	}

	displayStatusDaNota(venda: ResumoDaVenda) {
		const nota = venda.notas[0]
		return nota && mapErrosSefaz[nota.cstat]
			? nota.cstat + ' - ' + mapErrosSefaz[nota.cstat]
			: nota?.cstat || ''
	}

	displayNotasFiscais(venda: ResumoDaVenda): string {
		return venda.notas
			.filter(({ cstat }) => !['101', '102'].includes(cstat))
			.map(nota => `Nota ${nota.nnf} / ${nota.serie}`)
			.join('<br>')
	}	

	get itensFormatados() {
		return this.vendas.map(venda => ({
			...venda,
			displayNfSerie: this.displayNfSerie(venda),
			displayCliente: obterDisplayClienteDaVenda(venda),
			displayTotalDaVenda: this.displayTotalDaVenda(venda),
			displayStatusDaNota: this.displayStatusDaNota(venda),
			numeroDaNota: venda.notas[0] ? venda.notas[0].nnf : null,
			serieFiscal: venda.notas[0] ? venda.notas[0].serie : null,
		}))
	}

	podeCancelar(notas: NotaDaVenda[]) {
		return !notas.length ||	notas.some(({ cstat }) => cstat !== '101' && cstat !== '102')		
	}

	podeCancelarPagamento(venda) {
		if (!venda.pontoDeVenda.loja.configuracaoDaLoja) return true
		const limiteDeDias = venda.pontoDeVenda.loja.configuracaoDaLoja.limiteDeDiasParaCancelamentoAposEmissaoDaNota || 0

		const temNotasValidas = venda.notas.some(({ cstat }) => cstat !== '101' && cstat !== '102')

		if (temNotasValidas && limiteDeDias > 0) {
			const dhEmiMaisLimite = new Date(venda.notas[0].dhEmi)
			dhEmiMaisLimite.setDate(dhEmiMaisLimite.getDate() + limiteDeDias)

			if (limiteDeDias > 0 && new Date() <= dhEmiMaisLimite) {
				return true
			}
		} else if (venda.notas == 0) {
			return true
		}

		return false
	}

	async cancelarVenda(
		venda: ResumoDaVenda,
		motivoDoCancelamentoFiscal?: MotivosCancelamentoFiscal,
	) {
	
		if (!venda) {
			AlertModule.setError('Venda a cancelar não encontrada')
			return
		}
		try {
			if(!venda.possuiPagamentoTefCancelado && venda.possuiPagamentoTefCreditado){
				AlertModule.setError("É necessário estornar o pagamento antes de cancelar!")
				return
			}

			if (this.cancelando.includes(venda.id))
				throw new Error('Venda já está em cancelamento')

			this.cancelando.push(venda.id)
			const params = {
				motivo: motivoDoCancelamentoFiscal,
			}
			const vendaAtualizada = await this.cancelarVendaUseCase.cancelar(
				venda.id,
				params,
			)


			const vendaResumida = {
				...vendaAtualizada,
				total: obterTotalDaVenda(vendaAtualizada),
				vendaOrigem: undefined,
				chaveDanfeExterna: null,
			} as ResumoDaVenda
			this.vendas = this.vendas.map(venda =>
				venda.id === vendaAtualizada.id ? vendaResumida : venda,
			)
			AlertModule.setSuccess(`Venda ${venda.identificador} cancelada com sucesso`)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.cancelando = this.cancelando.filter(id => venda.id !== id)
			this.buscar()
		}
	}

	vendaPossuiNota(venda: ResumoDaVenda): boolean {
		return venda.notas.length > 0;
	}

	async cancelarNotasFiscais(
		venda: ResumoDaVenda, 
		motivoDoCancelamentoFiscal: string,
	) {
	
		if (!venda.notas.length) {
			AlertModule.setError('Venda não possui notas fiscais emitidas')
			return
		}

		this.cancelandoNotasFiscais.push(venda.id)
		if (!motivoDoCancelamentoFiscal) {
			AlertModule.setError('Necessário um motivo para cancelamento')
			return
		}

		try {
		
			const vendaAtualizada = await this.cancelarNotaFiscalUseCase.execute(
				venda.id,
				motivoDoCancelamentoFiscal,
			)

			const vendaResumida = {
				...vendaAtualizada,
				total: obterTotalDaVenda(vendaAtualizada),
				vendaOrigem: undefined,
				chaveDanfeExterna: null,
			} as ResumoDaVenda
			this.vendas = this.vendas.map(venda =>
				venda.id === vendaAtualizada.id ? vendaResumida : venda,
			)
			const notasList = vendaAtualizada.notas
				.map(nota => `Nota ${nota.nnf} / ${nota.serie} cancelada com sucesso`)
				.join('<br>')
			AlertModule.setSuccess({
				text: notasList,
				timeout: -1,
			})

			this.cancelandoNotasFiscais = this.cancelandoNotasFiscais.filter(
				id => venda.id !== id,
			)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.cancelandoNotasFiscais = this.cancelandoNotasFiscais.filter(id => venda.id !== id)
			this.buscar()
		}		
	}

	gerarDataHora(
		data?: string | null,
		hora?: string | null,
	): string | undefined {
		return moment(`${data} ${hora}`, 'YYYY-MM-DD HH:mm').toISOString(true)
	}

	aplicarFiltros() {
		this.buscar()
	}

	mounted() {
		if (this.exibirSomenteVendasDoPdvAtivo === false) {
			this.filtro.pdvId = ''
			this.filtro.lojaId = ''
		}

		if (this.$route.query.identificador) {
			this.filtro.identificador = (this.$route.query.identificador as string)
		}
	}

	get turnoDeVenda() {
		return VendaModule.turnoDeVenda
	}

	aplicarIds() {
		if (!this.turnoDeVenda?.pontoDeVenda.ativo) return
		if (!this.turnoDeVenda.pontoDeVenda.id) return
		this.filtro.lojaId = this.turnoDeVenda.pontoDeVenda.loja.id
		this.filtro.pdvId = this.turnoDeVenda.pontoDeVenda.id
	}

	async buscar() {
		await nextTick()
		try {
			this.buscando = true
			this.buscandoGridDemonstracao = true

			const dataHoraInicial = this.gerarDataHora(
				this.filtro.datas[0],
				this.filtro.horas[0] || '00:00',
			)
			const dataHoraFinal = this.gerarDataHora(
				this.filtro.datas[1],
				this.filtro.horas[1] || '23:59',
			)

			if (this.exibirSomenteVendasDoPdvAtivo !== false && this.tab === 0) {
				this.aplicarIds()
				const page = await this.findUseCase.list({
					dataHoraInicial,
					dataHoraFinal,
					lojaId: this.filtro.lojaId || undefined,
					cliente: removerFormatacaoDeCnpjOuCpf((
						typeof this.filtro.cliente === 'string'
							? this.filtro.cliente
							: this.filtro.cliente?.cnpjOuCpf) || null,
					) || undefined,
					numeroNota: this.filtro.numeroDaNota || undefined,
					serie: this.filtro.serieFiscal || undefined,
					pdvId: this.filtro.pdvId || undefined,
					exibeVendasComErros: this.filtro.exibeVendasComErros,
					ambientes: this.filtro.ambientes.join(',') || undefined,
					identificador: this.filtro.identificador || undefined,
					vendasComOrcamento: this.filtro.vendasComOrcamento || undefined,
					vendasComPagamentoCancelado: this.filtro.vendasComPagamentoCancelado || undefined,
					page: this.paginacao.page,
					size: this.paginacao.itemsPerPage,
					sort: 'dataHora,desc',
					origem: this.filtro.origem || undefined,							
					vendasConsignadaDemonstracao:
						this.filtro.vendasConsignadaDemonstracao || undefined,
					vendedorId: this.filtro.vendedorId || undefined,
				})
				this.vendas = page.content
				this.totalRegistros = page.totalElements
				this.paginacao.itemsLength = page.totalElements
			} else if(this.tab === 0) {
				const page = await this.findUseCase.list({
					dataHoraInicial,
					dataHoraFinal,
					lojaId: this.filtro.lojaId || undefined,
					cliente:
						removerFormatacaoDeCnpjOuCpf(
							(typeof this.filtro.cliente === 'string'
								? this.filtro.cliente
								: this.filtro.cliente?.cnpjOuCpf) || null,
						) || undefined,
					numeroNota: this.filtro.numeroDaNota || undefined,
					serie: this.filtro.serieFiscal || undefined,
					pdvId: this.filtro.pdvId || undefined,
					exibeVendasComErros: this.filtro.exibeVendasComErros,
					ambientes: this.filtro.ambientes.join(',') || undefined,
					identificador: this.filtro.identificador || undefined,
					vendasComOrcamento: this.filtro.vendasComOrcamento || undefined,
					vendasComPagamentoCancelado: this.filtro.vendasComPagamentoCancelado || undefined,
					origem: this.filtro.origem || undefined,
					page: this.paginacao.page,
					size: this.paginacao.itemsPerPage,
					sort: 'dataHora,desc',
					vendasConsignadaDemonstracao:
						this.filtro.vendasConsignadaDemonstracao || undefined,
					vendedorId: this.filtro.vendedorId || undefined,
				})
				this.vendas = page.content
				this.totalRegistros = page.totalElements
				this.paginacao.itemsLength = page.totalElements
			}

			if(this.tab === 1){
				const demonstracaoPage = await this.findUseCase.findHierarquiaDemonstracoes({
					dataHoraInicial,
					dataHoraFinal,
					lojaId: this.filtro.lojaId || undefined,
					cliente: removerFormatacaoDeCnpjOuCpf((
						typeof this.filtro.cliente === 'string'
							? this.filtro.cliente
							: this.filtro.cliente?.cnpjOuCpf) || null,
					) || undefined,
					numeroNota: this.filtro.numeroDaNota || undefined,
					serie: this.filtro.serieFiscal || undefined,
					pdvId: this.filtro.pdvId || undefined,
					exibeVendasComErros: this.filtro.exibeVendasComErros,
					ambientes: this.filtro.ambientes.join(',') || undefined,
					identificador: this.filtro.identificador || undefined,
					vendasComOrcamento: this.filtro.vendasComOrcamento || undefined,
					vendasComPagamentoCancelado: this.filtro.vendasComPagamentoCancelado || undefined,
					page: this.paginacaoDeDemonstracoes.page,
					size: this.paginacaoDeDemonstracoes.itemsPerPage,
					sort: 'dataHora,desc',
					origem: this.filtro.origem || undefined,							
					vendasConsignadaDemonstracao:
						this.filtro.vendasConsignadaDemonstracao || undefined,
					vendedorId: this.filtro.vendedorId || undefined,
				})

				this.demonstracaoHierarquia = demonstracaoPage.content			
				this.totalRegistrosDeDemonstracoes = demonstracaoPage.totalElements
				this.paginacaoDeDemonstracoes.itemsLength = demonstracaoPage.totalElements
			}
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.buscando = false
			this.buscandoGridDemonstracao = false
			this.dialogoDeFiltros.ocultar()
		}
	}

	async limparSelecaoDeFiltros() {
		try {
			this.buscando = true
			this.buscandoGridDemonstracao = true

			this.$router
				.push({
					query: { undefined },
				})
				.catch()

			if (this.exibirSomenteVendasDoPdvAtivo !== false) {
				this.aplicarIds()
				const page = await this.findUseCase.list({
					lojaId: this.filtro.lojaId || undefined,
					pdvId: this.filtro.pdvId || undefined,
					page: this.paginacao.page,
					size: this.paginacao.itemsPerPage,
					sort: 'dataHora,desc',
				})

				this.vendas = page.content
				this.totalRegistros = page.totalElements
			} else {
				const page = await this.findUseCase.list({
					page: this.paginacao.page - 1,
					size: this.paginacao.itemsPerPage,
					sort: 'dataHora,desc',
				})

				this.vendas = page.content
				this.totalRegistros = page.totalElements
			}
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.buscando = false
			this.buscandoGridDemonstracao = false
			this.dialogoDeFiltros.ocultar()
		}
	}

	abrirFiltros() {
		this.dialogoDeFiltros.mostrar()
	}

	@Watch('filtro', { deep: true })
	onChangeFiltro(newFiltro) {
		if (this.filtro.lojaId == null) {
			this.filtro.pdvId = ''
		}
		this.filtro.datas[0] =
			this.filtro.datas[0] !== null && this.filtro.datas[1] === null
				? null
				: this.filtro.datas[0]
		const stringified = JSON.stringify(newFiltro)
		localStorage.setItem(FILTRO_DE_VENDAS, stringified)
	}

	atualizarVenda(vendaAtualizada: ResumoDaVenda) {
		const indiceDaVenda = this.itensFormatados.findIndex(
			({ id }) => id === vendaAtualizada.id,
		)
		this.vendas.splice(indiceDaVenda, 1, vendaAtualizada)
	}

	navegar(page) {
		this.paginacao.page += page
		this.buscar()
	}

	navegarDemonstração(page) {
		this.paginacaoDeDemonstracoes.page += page
		
		this.buscar()
	}

	async enviarPorEmail(venda: ResumoDaVenda) {
		try {
			if (this.enviandoEmail.includes(venda.id))
				throw new Error('Está venda já tem um processo de envio em andamento')

			if (venda.cliente) {
				if (venda.cliente.email) {
					this.enviandoEmail.push(venda.id)
					await this.editarUmaVendaUseCase.enviarEmail(
						venda.id,
						venda.cliente.email,
					)
				} else {
					this.dialogoDeInformarEmail.mostrar(venda)
				}
			} else {
				this.dialogoDeInformarEmail.mostrar(venda)
			}
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.enviandoEmail = this.enviandoEmail.filter(id => venda.id !== id)
		}
	}

	async enviarValesPorEmail(venda: ResumoDaVenda) {
		try {
			if (this.enviandoEmail.includes(venda.id))
				throw new Error('Está venda já tem um processo de envio em andamento')

			if (venda.cliente) {
				if (venda.cliente.email) {
					this.enviandoEmail.push(venda.id)
					await new EmailUseCase().enviarValesPorEmail(
						venda.id,
						venda.cliente.email,
					)
				} else {
					this.dialogoDeInformarEmail.mostrar(venda)
				}
			} else {
				this.dialogoDeInformarEmail.mostrar(venda)
			}
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.enviandoEmail = this.enviandoEmail.filter(id => venda.id !== id)
		}
	}

	temNotaParaEnviarEmail(venda: ResumoDaVenda) {
		return (
			venda.notas.filter((nota: NotaDaVenda) => nota.cstat === '100').length > 0
		)
	}

	cancelaVendaComMotivo(venda) {
		return venda.notas.some(({ cstat }) => cstat !== '101' && cstat !== '102')		
	}

	async verificarVendaNaoEletronica(venda: ResumoDaVenda) {
		try {
			if (this.verificandoVenda.includes(venda.id))
				throw new Error('Está venda já tem um processo de envio em andamento')

			this.verificandoVenda.push(venda.id)
			const vendaAtualizada = await gerarNotaNaoEletronica(venda.id)
			
			this.vendas = this.vendas.map(venda =>
				venda.id === vendaAtualizada.id ? vendaAtualizada : venda,
			)

			AlertModule.setSuccess('Venda verificada com sucesso')
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.verificandoVenda = this.verificandoVenda.filter(id => venda.id !== id)
		}
	}
}
