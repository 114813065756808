<template>
	<div class="d-flex justify-end align-items-center">
		<div 
			v-if="params.data.notaFiscal.length > 0" 
			class="circle-icon"
			:class="params.data.ambiente === 'HOMOLOGACAO' ? 'grey' : 'green'"
		></div>
		<div 
			class="text-no-wrap pr-1" 
			:class="{ 'text-decoration-line-through': params.data.status === '101' }"
		>
			{{ params.data.displayNfSerie }}
		</div>
	</div>
</template>
<script>
export default {
	props: ["params"],
};
</script>
<style scoped>
.circle-icon {
	width: 8px;
	height: 8px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 5px;
}

.green {
	background-color: #43a047;
}

.grey {
	background-color: #353535;
}

.d-flex {
	display: flex;
	align-items: center;
}

.text-no-wrap {
	white-space: nowrap;
}

.text-decoration-line-through {
	text-decoration: line-through;
}
</style>