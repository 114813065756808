<template>
	<div class="d-flex justify-end align-items-center">
		<div 
			class="text-right"
			:style="{
				color: params.data.isDemonstracao && params.data.total > 0 ? '#FFB400' : (params.data.total >= 0 ? 'green' : 'red')
			}"
			:class="{ 'text-decoration-line-through': params.data.situacao === 'Cancelada' }"
		>
			{{ params.data.displayTotalDaVenda }}
		</div>
		<div
			v-if="params.data.total >= 0 && !params.data.isDemonstracao"
			class="arrow-icon arrow-up"
			:style="{ color: params.data.total >= 0 ? 'green' : 'red' }"
		></div>
		<div 
			v-if="params.data.isDemonstracao && params.data.total > 0"
			class="demonstracao-dot"
			:style="{
				backgroundColor: '#FFB400',
				borderColor: '#FFB400'
			}"
		></div>
		<div
			v-if="params.data.total < 0"
			class="arrow-icon arrow-down"
			:style="{ color: params.data.total < 0 ? 'red' : 'green' }"
		></div>
	</div>
</template>
  
<script>
export default {
	props: ["params"],
};
</script>
  
  <style scoped>
  .d-flex {
	display: flex;
  }
  
  .align-items-center {
	align-items: center; /* Isso garante que a seta ficará centralizada verticalmente */
  }
  
  .arrow-icon {
	display: inline-block;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	margin-left: 5px;
  }
  
  .arrow-up {
	border-bottom: 10px solid currentColor; /* Seta para cima */
  }
  
  .arrow-down {
	border-top: 10px solid currentColor; /* Seta para baixo */
  }
  
  .text-right {
	font-size: 16px;
	font-weight: 600;
  }
  
  .text-decoration-line-through {
	text-decoration: line-through;
  }
  
  .demonstracao-dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-left: 8px;
	background-color: #FFB400;
	border: 2px solid #FFB400;
  }
  </style>