







































































import { Component, Vue, Prop, Ref, Watch } from "vue-property-decorator";
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-enterprise";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef, GetDataPath, GetRowIdFunc, GridReadyEvent } from "@ag-grid-community/core";
import { MotivosCancelamentoFiscal, VendaComReferencias, VendaDemonstracaoResumida } from "@/models";
import {
	obterDisplayClienteDaDemonstracaoResumida,
} from '@/shareds/venda-shareds';
import mapErrosSefaz from "@/shareds/fiscal/tabelas/mapErrosSefaz";
import { formatarMoeda } from "@/shareds/formatadores";
import { GridOptions, LicenseManager } from "ag-grid-enterprise";
import TotalVendaRenderer from "./components-grid-demonstracao/TotalVendaRenderer.vue"
import ClienteGridDemonstracao from "./components-grid-demonstracao/ClienteGridDemonstracao.vue";
import NotaGridDemonstracao from "./components-grid-demonstracao/NotaGridDemonstracao.vue";
import ModalidadeGridDemonstracao from "./components-grid-demonstracao/ModalidadeGridDemonstracao.vue";
import { dateTimeToPtBrFormat } from "@/shareds/date/date-utils";
import AcoesGridDemonstracao from "./components-grid-demonstracao/AcoesGridDemonstracao.vue";
import Confirmacao from "./Confirmacao.vue";
import ConfirmacaoComMotivoFiscal from "./ConfirmacaoComMotivoFiscal.vue";
import DialogoDeDetalhesDaVenda from "../venda/DialogoDeDetalhesDaVenda.vue";
import { FindVendaUseCase } from "@/usecases";
import DialogoDeCancelarPagamentos from "../venda/DialogoDeCancelarPagamentos.vue";
import RodapePersonalizado from "./data-tables/RodapePersonalizado.vue";
import { AG_GRID_LOCALE_BR } from '@ag-grid-community/locale'

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-066566}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Almode}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Almode}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Almode}_need_to_be_licensed___{Almode}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{7_September_2025}____[v3]_[01]_MTc1NzE5OTYwMDAwMA==9c5f2e3bec6aeda6768b2994297ce2a8")

@Component({
	components: { 
		AgGridVue,
		Confirmacao,
		ConfirmacaoComMotivoFiscal,
		DialogoDeDetalhesDaVenda,
		DialogoDeCancelarPagamentos,
		RodapePersonalizado,
	},
})
export default class GridDeDemonstracoes extends Vue {
	@Ref() agGrid!: AgGridVue | undefined
	@Ref() confirmacao!: Confirmacao
	@Ref() dialogoDeDetalhesDaVenda!: DialogoDeDetalhesDaVenda
	@Ref() confirmacaoComMotivoFiscal!: ConfirmacaoComMotivoFiscal
	@Ref() dialogoDeCancelarPagamentos!: DialogoDeCancelarPagamentos
	@Ref() confirmarEmail!: Confirmacao
	@Ref() rodapePersonalizado!: RodapePersonalizado
	@Prop({ type: Array, required: true }) vendas!: VendaDemonstracaoResumida[]
	@Prop({ type: Number, required: false }) total?: number
	@Prop({ type: Boolean, required: true }) loading!: boolean
	@Prop({ type: Object }) paginacao!: { page: number; itemsPerPage: number; itemsLength: number }

	private gridApi: any = null

	dateTimeToPtBrFormat = dateTimeToPtBrFormat	
	findVendaUseCase = new FindVendaUseCase()
	vendaControlada: VendaDemonstracaoResumida | null = null
	vendaDetalhada: VendaComReferencias | null = null

	AG_GRID_LOCALE_BR = AG_GRID_LOCALE_BR

	columnDefs: ColDef[] = [
		{ headerName: "Modalidade", field: "modalidade",
			cellRenderer: (params) => {
				const ComponentClass = Vue.extend(ModalidadeGridDemonstracao)
				return new ComponentClass({
					propsData: { params },
				}).$mount().$el;
			}, 
		},
		{ headerName: "Data", field: "displayDataHora" },
		{ headerName: "Loja", field: "loja" },
		{ headerName: "Cliente", field: "displayCliente",
			cellRenderer: (params) => {
				const ComponentClass = Vue.extend(ClienteGridDemonstracao);
				return new ComponentClass({
					propsData: { params },
				}).$mount().$el;
			},
		},
		{ headerName: "NF/Serie", field: "displayNfSerie", width: 30, tooltipField: "displayAmbiente",
			cellRenderer: (params) => {
				const ComponentClass = Vue.extend(NotaGridDemonstracao);
				return new ComponentClass({
					propsData: { params },
				}).$mount().$el;
			},
		},
		{ headerName: "Último Status", field: "displayStatusDaNota" },
		{ headerName: "Total (R$)", field: "displayTotalDaVenda", 
			cellRenderer: (params) => {
				const ComponentClass = Vue.extend(TotalVendaRenderer);
				return new ComponentClass({
					propsData: { params },
				}).$mount().$el;
			},
			tooltipField: "displayTipoDeVenda",
		},{
			headerName: "Ações",
			field: "acoes",
			cellRenderer: (params) => {
				const ComponentClass = Vue.extend(AcoesGridDemonstracao);
				const instance = new ComponentClass({
					propsData: { params },
				});
				instance.$on("acao", (acaoData) => {
					this.handleAcaoClicada(acaoData.acao, acaoData.dados);
				});

				return instance.$mount().$el;
			},
		},
	];

	gridOptions: GridOptions = {
		rowHeight: 40,
		tooltipShowDelay: 100,
	};

	autoGroupColumnDef: ColDef = {
		headerName: "Identificador",
		cellRenderer: "agGroupCellRenderer",
		cellRendererParams: {
			suppressCount: true,
		},
		minWidth: 250, 
	};

	defaultColDef = {
		flex: 1,
		minWidth: 10,
		tooltipField: "tooltip",
	};

	levalOld = 0;

	reloadGrid = false

	getDataPath: GetDataPath = (data) => data.hierarquia;
	getRowId: GetRowIdFunc = (params) => params.data.hierarquia[params.data.hierarquia.length - 1];

	onGridReady(params: GridReadyEvent) {
		this.gridApi = params.api;
		this.gridApi.updateGridOptions({ rowData: this.getData() })
		this.aplicarEstilosDeNiveis()
	}

	getData(): any[] {
		return this.itensFormatados;
	}

	get itensFormatados() {
		return this.vendas.map(venda => ({
			...venda,
			displayCliente: obterDisplayClienteDaDemonstracaoResumida(venda),
			displayTotalDaVenda: this.displayTotalDaVenda(venda),
			displayStatusDaNota: this.displayStatusDaNota(venda),
			displayNfSerie: this.displayNfSerie(venda),
			displayDataHora: this.displayDataFormatada(venda),
			displayAmbiente: this.getAmbiente(venda),
			displayTipoDeVenda: this.getTipoDeVenda(venda),
		}))
	}

	displayDataFormatada(venda: VendaDemonstracaoResumida){
		return dateTimeToPtBrFormat(venda.dataHora)
	}

	displayTotalDaVenda(venda: VendaDemonstracaoResumida) {
		return formatarMoeda(venda.total)
	}

	displayStatusDaNota(venda: VendaDemonstracaoResumida) {
		return venda && mapErrosSefaz[venda.status]
			? venda.possuiNotaNaoEletronica ? 
				'Não eletrônica'
				:
				venda.status + ' - ' + mapErrosSefaz[venda.status]
			: venda?.status || ''
	}

	displayNfSerie(venda: VendaDemonstracaoResumida) {
		const nota = venda.notaFiscal;
		return nota
			? venda.possuiNotaNaoEletronica ? 
				`NE ${nota}`
				:
				`${venda.modeloNF} ${nota}`
			: '';
	}

	@Watch('vendas')
	@Watch('$vuetify.theme.dark')
	aplicarEstilosDeNiveis() {
		const niveis = this.vendas.map(venda => venda.hierarquia.length);
		const maxNivel = Math.max(...niveis)
		const css = document.createElement('style');

		for (let i = 0; i < maxNivel; i++) {
			css.innerHTML += `
				.ag-row-group-indent-${i + 1} {
					padding-left: ${24 * (i + 1)}px !important;
				}
			`;
		}
		
		document.head.appendChild(css);
	}

	getAmbiente(venda: VendaDemonstracaoResumida){
		return venda.ambiente === 'HOMOLOGACAO' ? 'Homologação' : 'Produção'
	}

	getTipoDeVenda(venda: VendaDemonstracaoResumida){
		if(venda.isDemonstracao && venda.total > 0) return venda.modalidade
		else if(venda.total >= 0 && !venda.isDemonstracao) return 'Venda'
		else return 'Devolução'
	}

	getRowStyle(params: any) {
		const hasChildren = params.node.__hasChildren
		const isDarkTheme = this.$vuetify.theme.dark

		if (hasChildren === undefined) return null

		const backgroundColor = hasChildren 
			? (isDarkTheme ? "#333333" : "#D3D3D3")
			: (isDarkTheme ? "#1E1E1E" : "white")

		return {
			backgroundColor,
		}
	}

	@Watch('$vuetify.theme.dark')
	onThemeChange() {
		if (this.gridApi) {
			this.gridApi.refreshCells({ force: true })
			this.gridApi.redrawRows()
			this.gridApi.refreshClientSideRowModel('group')
		}
	}

	abrirHierarquia() {
		let count = 0;

		for (const venda of this.vendas) {
			if (venda.hierarquia.length === 1) {
				count++
				if (count > 1) return 0
			}
		}

		return count === 1 ? -1 : 0
	}

	@Watch('loading')
	changeLoading() {
		this.loading
			? this.gridApi.showLoadingOverlay()
			: this.gridApi.hideOverlay()
		
	}

	async handleAcaoClicada(acao: string, venda: VendaDemonstracaoResumida) {
		this.vendaControlada = venda;

		if(acao === "detalhes" || acao === "enviar-email"){
			this.vendaDetalhada = await this.findVendaUseCase.getVendaComReferencias(venda.id)
		}
		this.$nextTick(() => {
			if (acao === "cancelar") this.confirmacao.mostrar();
			else if (acao === "cancelar-com-motivo") this.confirmacaoComMotivoFiscal.mostrar();
			else if (acao === "detalhes") this.dialogoDeDetalhesDaVenda.mostrar(this.vendaDetalhada);
			else if (acao === 'cancelar-pagamento') this.dialogoDeCancelarPagamentos.mostrar(venda)
			else if (acao === 'enviar-email') this.confirmarEmail.mostrar()
		});
	}


	cancelarVenda(venda: VendaDemonstracaoResumida, motivoDoCancelamentoFiscal?: MotivosCancelamentoFiscal) {
		this.$emit("confirmarCancelamento", venda, motivoDoCancelamentoFiscal)
		this.vendaControlada = null
	}

	enviarPorEmail() {
		this.$emit("enviarEmail", this.vendaDetalhada)
		this.vendaControlada = null
	}

	navegar(page) {
		this.$emit("paginacaoDemonstracao", page)
	}

	@Watch('$vuetify.theme.dark')
	onChangeTheme() {
		this.reloadGrid = true
		setTimeout(() => {
			this.reloadGrid = false
		}, 1)
	}

	autoSizeStrategy: {
		type: "fitGridWidth"
		defaultMinWidth: number
		columnLimits: Array<{ colId: string; minWidth: number }>
	} = {
		type: "fitGridWidth",
		defaultMinWidth: 70,
		columnLimits: [
			{
				colId: "Identificador",
				minWidth: 400,
			},
		],
	};
}
